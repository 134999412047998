* {
  transition: all 0.1s ease-in-out;
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: lightgrey;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
}
